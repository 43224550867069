<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card v-if="!isDeptDeleted">
      <v-tabs show-arrows>
        <v-tab>测量控制</v-tab>
        <v-tab>基本信息设置</v-tab>
        <v-tab>登录设置</v-tab>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isLoggedAsDepartment"
          class="mt-2 mr-4"
          text
          color="red"
          @click="isDeletingDept = true"
        >
          删除被试单位
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tab-item>
          <AdminConfigDeptStatistic :guid="guid" :name="deptName" />
        </v-tab-item>
        <v-tab-item>
          <AdminConfigDeptInfo :guid="guid" />
        </v-tab-item>
        <v-tab-item>
          <CredentialsEditor :guid="guid" :entity="departmentEntityType" />
        </v-tab-item>
      </v-tabs>
    </v-card>
    <AppDialog
      v-model="isDeletingDept"
      size="small"
      :title="`确定要删除被试单位 “${deptName}” 吗？`"
      color="red"
      action-text="删除"
      :loading="isDialogLoading"
      @confirm="removeOneDept"
    >
      删除后无法恢复
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AdminConfigDeptStatistic from "@/components/admin/AdminConfigDeptStatistic";
import AdminConfigDeptInfo from "@/components/admin/AdminConfigDeptInfo";
import CredentialsEditor from "@/components/CredentialsEditor";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters, mapActions } from "vuex";
import { userEntityTypes } from "@/utils/constants/user";
import { fetchDeptDetails, deleteOneDept } from "@/api/dept";

export default {
  components: {
    AdminConfigDeptStatistic,
    AdminConfigDeptInfo,
    CredentialsEditor,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      isDeletingDept: false,
      isDeptDeleted: false,
      deptName: "",
      errorMsg: ""
    };
  },

  watch: {
    guid(newGuid) {
      this.isDeptDeleted = false;
      return this.reloadDeptName(newGuid);
    }
  },

  computed: {
    ...mapGetters({
      userEntity: "user/userEntity"
    }),
    departmentEntityType() {
      return userEntityTypes.department;
    },
    isLoggedAsDepartment() {
      return this.userEntity === this.departmentEntityType;
    }
  },

  methods: {
    ...mapActions({
      refreshDeptList: "dept/refreshDeptList"
    }),
    async reloadDeptName(guid) {
      this.isLoading = true;
      let deptDetails = await fetchDeptDetails(guid);
      this.deptName = deptDetails.departmentName;
      this.isLoading = false;
    },
    async removeOneDept() {
      try {
        this.isDialogLoading = true;
        await deleteOneDept(this.guid);
        await this.refreshDeptList();
        this.deptName = "";
        this.isDeptDeleted = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDeletingDept = false;
      this.isDialogLoading = false;
    }
  },

  created() {
    this.reloadDeptName(this.guid);
  }
};
</script>
