<template>
  <v-form ref="form">
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-alert
        type="success"
        dense
        transition="slide-y-transition"
        :value="isUpdateSuccess"
        >更新成功！</v-alert
      >
      <v-alert
        type="error"
        dense
        transition="slide-y-transition"
        :value="!!errorMsg"
        >{{ errorMsg }}</v-alert
      >
      <v-card-title>基本信息</v-card-title>
      <div class="px-6">
        <v-text-field
          filled
          dense
          label="名称"
          hint="被试单位的显示名称"
          v-model="deptName"
          :rules="fieldRules.deptName"
        ></v-text-field>
        <v-text-field
          filled
          dense
          label="被试单位识别码"
          hint="(非必填) “被试单位识别码”可以作为用户“识别号”的前缀，进行补充。例：学号之前可以添加班级的“识别码”进行唯一化处理"
          v-model="testeeNumPrefix"
        ></v-text-field>
      </div>
      <v-card-actions class="px-6 mb-6">
        <v-btn
          color="primary"
          depressed
          :disabled="isBtnLoading"
          :loading="isBtnLoading"
          @click="saveChanges"
        >
          确认修改
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-form>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapActions } from "vuex";
import { fetchDeptDetails, saveDeptInfoChanges } from "@/api/dept";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      originalDeptName: "",
      originalTesteeNumPrefix: "",
      deptName: "",
      testeeNumPrefix: "",
      fieldRules: {
        deptName: [
          val => (val || "").length > 0 || "被试单位名称不能为空",
          val => (val || "").length < 25 || "被试单位名称不能超过25个字符"
        ]
      },
      isBtnLoading: false,
      errorMsg: "",
      dialogErrorMsg: "",
      isUpdateSuccess: false
    };
  },

  watch: {
    guid(newGuid) {
      return this.refreshDeptDetails(newGuid);
    }
  },

  computed: {
    isFieldChanged() {
      return (
        this.originalDeptName !== this.deptName ||
        this.originalTesteeNumPrefix !== this.testeeNumPrefix
      );
    }
  },

  methods: {
    ...mapActions({
      refreshDeptList: "dept/refreshDeptList"
    }),
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    showErrorMsg(msg) {
      this.errorMsg = msg;
      setTimeout(() => (this.errorMsg = ""), 3000);
    },
    async refreshDeptDetails(guid) {
      try {
        this.isLoading = true;
        let deptDetails = await fetchDeptDetails(guid);
        this.originalDeptName = this.deptName = deptDetails.departmentName;
        this.originalTesteeNumPrefix = this.testeeNumPrefix =
          deptDetails.numberPrefix;
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    async saveChanges() {
      let isValid = this.$refs.form.validate();
      // 未作任何改动或rules不通过时，直接跳过
      if (isValid && this.isFieldChanged) {
        try {
          this.isBtnLoading = true;
          await saveDeptInfoChanges(this.guid, {
            deptName: this.deptName,
            testeeNumPrefix: this.testeeNumPrefix
          });
          // 刷新dept列表
          await this.refreshDeptList();
          // 重新获取dept的基本信息
          await this.refreshDeptDetails(this.guid);
          // 显示成功alert
          this.showSuccessMsg();
        } catch (err) {
          this.showErrorMsg(err);
        }
        this.isBtnLoading = false;
      }
    }
  },

  created() {
    this.refreshDeptDetails(this.guid);
  }
};
</script>
