<template>
  <v-menu
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="mr-4 date-range-picker"
        :style="fieldStyle"
        :value="dateRangeText"
        prepend-inner-icon="mdi-calendar-text"
        :label="label"
        :filled="filled"
        :outlined="outlined"
        dense
        hide-details="auto"
        clearable
        v-on="on"
        v-bind="attrs"
        @click:clear="clearDateRange"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="localDateRange"
      full-width
      no-title
      range
      show-adjacent-months
      locale="zh-cn"
      :day-format="d => d.split('-')[2]"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import _ from "lodash";
import { getDateFromDateTime } from "@/utils/dateTime";

export default {
  props: {
    fieldStyle: {
      type: String
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: "350px"
    },
    dateRange: {
      type: Array,
      required: true
    }
  },

  model: {
    prop: "dateRange",
    event: "changed"
  },

  data() {
    return {
      localDateRange: this.dateRange
    };
  },

  watch: {
    dateRange(newVal) {
      this.localDateRange = newVal;
    },
    localDateRange(newVal) {
      // 不添加此条件，会造成死循环
      newVal !== this.dateRange &&
        this.$emit("changed", this.rebuildDateRange(newVal));
    }
  },

  computed: {
    dateRangeText() {
      let dText = "";
      if (this.localDateRange.length === 1) {
        dText = getDateFromDateTime(this.localDateRange[0]);
      } else if (this.localDateRange.length === 2) {
        dText = `${getDateFromDateTime(
          this.localDateRange[0]
        )} ~ ${getDateFromDateTime(this.localDateRange[1])}`;
      }
      return dText;
    }
  },

  methods: {
    rebuildDateRange(dateRangeArray) {
      let newDateRange = [];
      if (dateRangeArray.length === 1) {
        newDateRange = dateRangeArray;
      } else if (dateRangeArray.length === 2) {
        newDateRange = [_.min(dateRangeArray), _.max(dateRangeArray)];
      }
      return newDateRange;
    },
    clearDateRange() {
      this.localDateRange = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.date-range-picker {
  max-width: 400px;
  min-width: 290px;
}
</style>
